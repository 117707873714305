/* 1. Theme default css */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

:root {
  /* colors */
  --space-cadet: #142c4b;
  --cerise-pink: #e83e8c;
  --dav-grey: #4d505f;
  --ox-blue: #05154e;
  --stormcloud: #4f5a62;
  --lotion: #fafafa;
  --spanish-gray: #989d9f;
  --shadow-blue: #7a90a0;
  --columbia-blue: #c8dbed;
  --grediyent-color: linear-gradient(
    230.38deg,
    #142c4b 9.38%,
    #e83e8c 100%,
    #e83e8c 100%
  );
  --body-font: "Poppins", sans-serif;
  --body-text-color: #484b5b;
  --heading-color: #2e2e2e;
}

body {
  font-family: var(--body-font);
  font-weight: normal;
  font-style: normal;
  font-size: 15.1px;
  line-height: 23.99px;
}

.img {
  max-width: 100%;
  transition: all 0.3s ease-out 0s;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.fix {
  overflow: hidden;
}

a,
.button {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
  color: var(--body-text-color);
  text-decoration: none;
}

a,
button {
  color: var(--body-text-color);
  outline: medium none;
}

button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--body-font);
  font-weight: normal;
  color: var(--heading-color);
  margin-top: 0px;
  font-style: normal;
  font-weight: 700;
  text-transform: normal;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 27px;
}

h3 {
  font-size: 15px;
}

h4 {
  font-size: 32px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 17px;
}

.large-text {
  font-size: 17px !important;
}

.small-text {
  font-size: 12px !important;
}

/* color-classes */
.theme-text {
  color: var(--cerise-pink) !important;
}

.blue-text {
  color: var(--space-cadet) !important;
}

.grey-text {
  color: #bcbcbc !important;
}

/* bg-classes */
.light-grey-bg {
  background-color: #f8f8f8 !important;
}

/* font-weight */
.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

ul {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
}

p {
  font-weight: normal;
  color: var(--body-text-color);
  margin-bottom: 15px;
  font-size: 15.1px;
  line-height: 23.99px;
}

hr {
  border: 1px solid #e3e3e3;
  border-top: 0 none;
  margin: 30px 0;
  padding: 0;
}

label {
  color: #959595;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
}

*::-moz-selection {
  background: #d6b161;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

*::-moz-placeholder {
  color: #dfdfdf;
  font-size: 13px;
  opacity: 1;
}

*::placeholder {
  color: #dfdfdf;
  font-size: 13px;
  opacity: 1;
}

.theme-overlay {
  position: relative;
}

.theme-overlay::before {
  background: #1696e7 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}

.separator {
  border-top: 1px solid #f2f2f2;
}

/* button style */
.op-btn {
  -moz-user-select: none;
  background: var(--cerise-pink);
  border: medium none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 0;
  padding: 14px 36px;
  text-align: center;
  touch-action: manipulation;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.op-btn:hover {
  color: #fff;
  background-color: var(--ox-blue);
}

.op-btn.op-btn-blue {
  background-color: var(--space-cadet);
  color: #fff;
}

.op-btn.op-btn-blue:hover {
  background-color: var(--cerise-pink);
  color: #fff;
}

/* header-area */
.header {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 14;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.header.sticky {
  position: fixed;
  left: 0px;
  z-index: 13;
  width: 100%;
  height: auto;
  background: #fff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.09);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.header-menu ul li {
  padding: 0px 14px;
}

.header-menu ul li a {
  text-decoration: none;
  display: inline-block;
  line-height: 1.58;
  color: #b1b1b5;
  position: relative;
}

.header-menu ul li a::after {
  height: 2px;
  width: 80%;
  background-color: var(--cerise-pink);
  content: "";
  border-radius: 10px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  opacity: 0;
  visibility: hidden;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.header-menu ul li .active_nav {
  color: var(--space-cadet);
  font-weight: 600;
}

.header-menu ul li .active_nav::after {
  opacity: 1;
  visibility: visible;
  background-color: var(--cerise-pink);
}

.header-menu ul li a:hover {
  color: var(--space-cadet);
}

.header-right a.op-btn {
  padding: 13px 22px;
  font-size: 13px;
}

.header-padding {
  padding: 10px 0px;
}

/* header-area-end */
/* section-title */
.section-title span {
  margin-bottom: 6px;
}

.section-title h2 {
  color: #2e2e2e;
}

/* section-title-end */
/* default-css */
.button-link li a {
  text-decoration: none;
  display: inline-block;
  font: 16px;
  font-weight: 500;
  color: #142c4b;
  padding: 24px 22px;
  line-height: 1;
}

.button-link li:first-child a {
  padding-left: 0px;
}

.button-link li:last-child a {
  padding-right: 0px;
}

.button-link li a.active {
  color: var(--cerise-pink);
  font-weight: 700;
}

.button-link li a.large-text {
  padding: 20px 22px;
}

/* link-with-icon */
.link-with-icon {
}

.link-with-icon li {
  padding: 8px 0px;
}

.link-with-icon li:first-child {
  padding-top: 0px;
}

.link-with-icon li:last-child {
  padding-bottom: 0px;
}

.link-with-icon li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  color: #959595;
}

.link-with-icon li:hover a {
  color: var(--cerise-pink);
}

.link-with-icon li a span {
  width: 21px;
  height: 21px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.link-with-icon li a span img {
}

/* link-with-icon-end */
/* input-css */
.input-item {
  margin-bottom: 20px;
}

.input-item label {
  margin-bottom: 5px;
}

.input-box input {
  height: 53px;
  width: 100%;
  padding: 0px 17px;
  background: #fdfdfd;
  border: 1px solid #f4f4f4;
  border-radius: 2px;
}

.input-box textarea {
  padding: 10px 17px;
  background: #fdfdfd;
  border: 1px solid #f4f4f4;
  border-radius: 2px;
  height: 148px;
  resize: none;
  width: 100%;
}

/* input-css-end */
/* default-css-end */
/* hero-area */
.hero-padding {
  padding-top: 143px;
  padding-bottom: 134px;
}

.hero-area {
  background-color: #fff;
  z-index: 1;
}

.hero-content h1 {
  line-height: 78px;
}

.hero-content p {
  color: #a9a9a9;
  font-size: 18px;
  line-height: 30px;
}

.hero-content a.op-btn.op-btn.op-btn-blue {
  padding: 16px 45px;
  font-size: 15px;
}

.hero-object-1 {
  left: 22%;
  top: 20%;
  z-index: -1;
}

.hero-object-2 {
  right: 20%;
  bottom: 28%;
  z-index: -1;
}

/* hero-area-end */
/* mission-area */
.mission-padding {
  padding-top: 33px;
  padding-bottom: 60px;
}

.mission-area .section-title {
  margin-bottom: 65px;
}

.mission-card {
  border: 0.7px solid #d1d1d1;
  border-radius: 4.06446px;
  background-color: #e5e5e5;
  padding: 26px 50px 56px 40px;
  text-align: center;
  margin-bottom: 23px;
}

.mission-card.active {
  transform: scale(1.04);
  background: #ffffff;
  box-shadow: 0px 4.06446px 40.6446px rgba(0, 0, 0, 0.06);
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
  -ms-transform: scale(1.04);
  -o-transform: scale(1.04);
}

.missing-card-img {
  position: relative;
  height: 156px;
  margin: 0 auto;
  margin-bottom: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.missing-card-img span {
  display: inline-block;
  background-color: #efefef;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 131px;
  z-index: 1;
  width: 132px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.missing-card-img img {
  max-width: 100%;
  position: relative;
  z-index: 2;
}

.missing-card-content h5 {
  margin-bottom: 8px;
}

.missing-card-content p {
  margin-bottom: 0px;
}

.mission-card.active .missing-card-content h5 {
  color: var(--cerise-pink);
  margin-bottom: 10px;
}

.mission-card.active .missing-card-content p {
  color: #a0a0a0;
}

/* mission-area */
/* about-area */
.about-area {
  z-index: 1;
}

.about-area .section-title {
  margin-bottom: 64px;
}

.about-object-1 {
  left: 21%;
  top: 0px;
  z-index: -1;
}

.about-padding {
  padding-top: 97px;
  padding-bottom: 50px;
}

.team-card {
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  max-width: 330px;
  width: 100%;
  margin-bottom: 23px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.team-card-img {
  height: 258px;
}

.team-card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.team-card-content {
  padding: 17px 25px 19px;
}

.social-box a {
  height: 35px;
  width: 35px;
  background-color: #eff0ef;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.social-box a:hover {
  background-color: var(--columbia-blue);
}

/* social-box-round */
.social-box.socila-round a {
  height: 52px;
  width: 52px;
  background-color: var(--space-cadet);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.social-box.socila-round a:hover {
  background-color: var(--spanish-gray);
}

/* social-box-round-end */
/* about-area-end */
/* find-area */
.find-area {
  z-index: 1;
}

.find-object-1 {
  top: -94px;
  left: 21%;
  z-index: -1;
}

.find-object-2 {
  right: 21%;
  bottom: 0px;
  z-index: -1;
}

.find-area .section-title {
  margin-bottom: 65px;
}

.find-padding {
  padding-top: 50px;
  padding-bottom: 187px;
}

.find-img img {
  width: 100%;
  height: 481px;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.find-info {
  height: 481px;
  width: 100%;
  padding: 27px 43px 24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.find-info-content {
}

.find-info-item p {
  margin-bottom: 5px;
}

.find-info-item span {
  display: block;
  line-height: 1;
}

.find-info-item {
  margin-bottom: 30px;
}

.find-progress-area {
  margin-bottom: 26px;
}

.find-progress {
  width: 100%;
  height: 9px;
  background: rgba(255, 255, 255, 0.52);
  position: relative;
}

.find-progress-bar {
  position: absolute;
  left: 0px;
  width: 75%;
  background-color: var(--cerise-pink);
  height: 100%;
}

.find-info-head h4 {
  font-size: 30px;
  line-height: 1.5;
}

.find-info-head {
  margin-bottom: 30px;
}

/* find-area-end */
/* join-area */
.join-padding {
  padding-top: 49px;
  padding-bottom: 74px;
}

.join-area .section-title {
  margin-bottom: 72px;
}

.join-area {
  background-color: #f8f8f8;
}

.contact-wrapper {
  max-width: 379px;
}

.help-item-img {
  width: 100%;
  height: 149px;
}

.help-item-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.help-item {
  max-width: 149px;
  flex: 0 0 auto;
  width: 149px;
}

.help--item-content {
  padding: 5px 0px;
}

.help--item-content span {
  display: block;
  line-height: 1;
  margin-bottom: 4px;
}

.help--item-content p {
  margin-bottom: 4px;
}

.help--item-content a {
  display: block;
  line-height: 1;
}

.help-center {
  margin-bottom: 70px;
}

.contact-form {
  background-color: #fff;
  padding: 42px 39px;
  padding-bottom: 27px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.submit-btn a.op-btn {
  width: 198px;
}

/* join-area-end */
/* faq-area */
.faq-area .section-title {
  margin-bottom: 72px;
}

.faq-padding {
  padding-top: 100px;
  padding-bottom: 90px;
}

.faq.accordion .accordion-item {
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid #dcdcdc;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.faq.accordion .accordion-item:last-child {
  border-bottom: none;
}

.faq.accordion .accordion-button {
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 20px;
  color: var(--space-cadet);
  font-weight: 600;
  padding: 21.5px 12px;
  position: relative;
  padding-left: 100px;
}

.faq.accordion .accordion-button:focus {
  outline: none;
  box-shadow: none;
}

.faq.accordion .accordion-button span.acc-count {
  position: absolute;
  left: 27px;
}

.faq.accordion .accordion-button:not(.collapsed) {
  padding-bottom: 12px;
}

.faq.accordion .accordion-button:not(.collapsed)::after {
  background-color: var(--cerise-pink);
  background-image: url("../icon/minus.svg");
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.faq.accordion .accordion-button::after {
  height: 48px;
  width: 48px;
  background-color: #fff;
  background-image: url("../icon/plus.svg");
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
}

.faq.accordion .accordion-body {
  padding: 0px 12px;
  padding-bottom: 30px;
  padding-left: 100px;
}

.faq.accordion .accordion-body p {
  margin-bottom: 0px;
  color: #484b5b;
}

/* faq-area-end */
/* footer-area */
.footer-bg {
  background-color: var(--space-cadet);
}

.footer-top {
  padding-top: 85px;
  padding-bottom: 28px;
}

.footer-text {
  max-width: 236px;
}

.footer-text h6 {
  margin-bottom: 14px;
}

.footer-text p {
  color: #d6d6d6;
  font-size: 12px;
  line-height: 1.5;
}

.footer-shedule {
  margin-top: 38px;
  padding-left: 30px;
}

.footer-shedule span {
  position: absolute;
  left: 0px;
  top: 0px;
}

.footer-shedule p {
  color: #d6d6d6;
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 1.5;
}

.footer-widget h6 {
  margin-bottom: 14px;
}

.footer-widget ul li:first-child a {
  padding-top: 0px;
}

.footer-widget ul li a {
  display: block;
  padding: 5px 0px;
  line-height: 1.5;
  color: #d6d6d6;
  text-decoration: none;
  font-size: 12px;
}

.footer-widget ul li a:hover {
  color: var(--cerise-pink);
}

.footer-contact-item {
  position: relative;
  padding-left: 32px;
  padding-bottom: 12px;
}

.footer-contact-item span {
  position: absolute;
  left: 0px;
  top: 2px;
  display: inline-block;
  line-height: 1;
}

.footer-contact-item span img {
  max-width: 18px;
}

.footer-contact-item a {
  display: block;
  color: #d6d6d6;
  text-decoration: none;
  line-height: 1.5;
  font-size: 12px;
}

.footer-bottom {
  border-top: 1px solid #3f3f3fd4;
}

.footer-copy p {
  color: #d6d6d6;
  font-size: 11px;
}

.footer-social a:first-child {
  border-left: 1px solid #3f3f3fd4;
}

.footer-social a {
  display: inline-block;
  height: 60px;
  width: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #3f3f3fd4;
}

/* footer-area-end */
/* works-area */
.works-area {
}

.works-padding {
  padding-top: 50px;
  padding-bottom: 140px;
}

.works-area .section-title {
  margin-bottom: 40px;
}

.works-item {
  display: flex;
  align-items: center;
  max-width: 720px;
  width: 100%;
  justify-content: space-between;
  z-index: 1;
  position: relative;
}

.works-item:nth-child(1) .works-count {
  position: absolute;
  left: -77px;
}

.works-item:nth-child(1) .works-line-1 {
  top: 27%;
  left: 52%;
  max-width: 52%;
  z-index: -1;
  position: absolute;
}

.works-item:nth-child(2) .works-line-2 {
  position: absolute;
  left: -1%;
  max-width: 54%;
  top: 41%;
  z-index: -1;
}

.works-item:nth-child(2) .works-count {
  right: -77px;
  position: absolute;
  top: 13px;
}

.works-item:nth-child(3) .works-count {
  position: absolute;
  left: -93px;
}

.works-item:nth-child(3) .works-line-3 {
  position: absolute;
  top: 45%;
  left: 48%;
  z-index: -1;
  max-width: 53%;
}

.works-item:nth-child(4) .works-count {
  position: absolute;
  right: -77px;
  top: 0px;
}

.works-item:nth-child(4) .works-line-4 {
}

.works-content-inner {
  max-width: 292px;
}

.works-content-inner p {
  font-size: 14.3px;
  color: #484b5b;
  line-height: 1.54;
}

.works-count {
  display: inline-block;
  line-height: 47px;
  color: #fff;
  height: 47px;
  width: 47px;
  background-color: var(--ox-blue);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

/* works-area-end */
/* offcanvas-menu */
.mobail-bar span.bar {
  cursor: pointer;
  display: inline-block;
}

.offcanvas-wrapper.blue-bg {
  background-color: var(--space-cadet);
}

.offcanvas-wrapper {
  width: 212px;
  height: 100%;
  position: fixed;
  right: -212px;
  z-index: 12;
  top: 0px;
  opacity: 0;
  visibility: hidden;
  z-index: 14;
  padding: 24px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.offcanvas-wrapper.active {
  opacity: 1;
  visibility: visible;
  right: 0px;
}

.offcanvas-menu ul li a {
  color: #b1b1b5;
  display: inline-block;
  position: relative;
  font-size: 15px;
  text-decoration: none;
}

.offcanvas-menu ul li.active a {
  color: var(--cerise-pink);
}

.offcanvas-menu ul li.active a::after {
  opacity: 1;
  visibility: visible;
}

.offcanvas-menu ul li a::after {
  position: absolute;
  right: 0px;
  bottom: -2px;
  height: 2px;
  width: 38px;
  content: "";
  opacity: 0;
  visibility: hidden;
  background-color: var(--cerise-pink);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.offcanvas-menu ul li {
  padding: 16px 0px;
  display: block;
}

.offcanvas-menu {
  margin-top: 72px;
}

.offcanvas-link {
  margin-top: 100px;
}

.offcanvas-link a {
  display: inline-flex;
  align-items: center;
  color: var(--cerise-pink);
  text-decoration: none;
}

.offcanvas-link a span {
  display: inline-block;
}

.overlay.active {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 12;
}

/* offcanvas-menu-end */
/* CONTACT THANK TEXT POPUP */
.contact-thank-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: var(--cerise-pink);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.contact-thank-text h5 {
  color: var(--cerise-pink);
  text-transform: capitalize;
  font-weight: 600;
}

.contact-thank-text.active {
  visibility: visible;
  opacity: 1;
}

/* =====================home page end=============== */
/* ---pagination-section--- */
.pagination-section {
  /* padding: 40px 0 30px; */
  background: url("../img/pagination/pagination-bg.png") no-repeat center
    center/cover;
}

/* .pagination-section__marginTop {
   padding-top: 40px
} */

.pagination {
}

.pagination ul {
  display: flex;
}

.pagination ul li {
  color: #fff;
  white-space: nowrap;
}

.pagination ul li a {
  font-size: 15px;
  color: inherit;
  text-decoration: none;
}

.pagination ul li a.active {
  font-weight: 700;
}

/* ---pagination-section---end */
/* ---properties-section--- */
.properties-section {
  padding: 61px 0 50px;
  position: relative;
  z-index: 1;
}

.properties-section-inner {
}

.prop-shape {
  position: absolute;
  z-index: -1;
}

.prop-shape-tr {
  width: 196px;
  top: 0;
  right: 0;
}

.prop-shape-bl {
  width: 211px;
  bottom: 0;
  left: 0;
}

.properties-top {
  margin: 0 0 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.properties-top .left {
}

.properties-top .left .button-link {
}

.properties-top .left .button-link li {
  margin-right: 37px;
}

.properties-top .left .button-link li:last-child {
  margin-right: 0;
}

.properties-top .left .button-link li a {
  padding: 0;
}

.properties-top .right {
  display: flex;
}

.properties-top .right .ind-select {
  min-width: 223px;
  margin-right: 14px;
}

.properties-top .right .ind-select:last-child {
  margin: 0;
}

.ind-select {
  position: relative;
}

.ind-select::after {
  content: "";
  height: 8px;
  width: 8px;
  border-top: 1px solid #b1b1b1;
  border-left: 1px solid #b1b1b1;
  display: block;
  position: absolute;
  top: 50%;
  right: 23px;
  transform: translate(-50%, -50%);
  transform: rotate(-135deg);
  margin-top: -5px;
}

.ind-select .nice-select {
  font-size: 13px;
  color: #b1b1b1;
  height: 53px;
  width: 100%;
  padding: 0px 45px 0 12px;
  background: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 2px;
  appearance: none;
  line-height: 53px;
}

.ind-select .nice-select:focus {
  border-color: #e2e2e2;
}

.ind-select .nice-select::after {
  display: none;
}

.ind-select .nice-select option {
  background: #fff;
  color: #000;
}

.all-properties-wrap {
  grid-column-gap: 50px;
  grid-row-gap: 44px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.sgl-properties {
  display: flex;
}

.sgl-properties .fl-image {
  width: 100%;
}

.sgl-properties .fl-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}

.sgl-properties .find-right {
  width: 100%;
}

.sgl-properties .find-right .find-info {
  height: 100%;
}

/* ---properties-section---end */
/* ---property-details-section--- */
.property-details-section {
  padding: 55px 0;
}

.property-details-section-inner {
}

.property-details-title {
  margin: 0 0 20px;
  display: flex;
  justify-content: space-between;
}

.property-details-title .left {
}

.property-details-title .left h2 {
  font-size: 22px;
  margin: 0 0 3px;
  font-weight: 600;
  color: #142c4b;
}

.property-details-title .left .location-x {
  display: flex;
  align-items: center;
}

.location-x {
  font-size: 12.16px;
  color: #bcbcbc;
  text-decoration: none;
}

.location-x .icon {
  margin: 0 6px 0 0;
}

.property-details-title .right {
}

.property-details-title .right h3 {
  font-size: 24px;
  margin: 0 0 3px;
  line-height: 1;
  font-weight: 600;
  color: #e83e8c;
  display: flex;
  align-items: center;
}

.property-details-title .right .sm-txt {
  font-size: 16px;
  margin: 0 5px 0 0;
  font-weight: 500;
  color: #4d505f;
}

.property-details-title .right .r-txt {
}

.property-details-title .right p {
  font-size: 12.16px;
  color: #bcbcbc;
}

.property-details-img-gallery {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}

.p-gallery-item {
  position: relative;
}

.p-gallery-item.p-gallery-item-huge {
  grid-row: span 2;
  grid-column: span 2;
}

.p-gallery-item .full-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
}

.p-gallery-item .video-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.p-gallery-item .video-button:hover {
  opacity: 0.8;
}

.p-gallery-item .overlay-cont {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  background: rgba(0, 0, 0, 0.432);
  text-decoration: none;
  border-radius: 4px;
}

.property-details-row {
  margin: 10px 0 0;
  display: flex;
}

.property-details-main-column {
  flex-grow: 1;
}

.property-details-side-column-inner {
  position: sticky;
  top: 118px;
}

.property-details-side-column {
  width: 244px;
  margin-left: 67px;
  flex-shrink: 0;
  padding-top: 30px;
}

.pdmc-nav {
  padding: 30px 0 0;
  position: sticky;
  top: 60px;
  margin: 0 0 35px;
  background: #fff;
}

.pdmc-nav ul {
  display: flex;
  width: 100%;
  border-bottom: 3px solid #eaeaea;
}

.pdmc-nav ul li {
  margin-right: 30px;
}

.pdmc-nav ul li:last-child {
  margin-right: 0;
}

.pdmc-nav ul li a {
  font-size: 14px;
  font-weight: 600;
  color: #142c4b;
  text-decoration: none;
  display: flex;
  padding: 0 0px 5px 0;
  margin-bottom: -3px;
  border-bottom: 3px solid transparent;
  text-transform: uppercase;
}

.pdmc-nav ul li:last-child a {
  padding-right: 0;
}

.pdmc-nav ul li.active {
  color: #e83e8c;
  border-bottom-color: #e83e8c;
}

.pdmc-nav ul li a:hover {
  color: #e83e8c !important;
}

.pdmc-box {
  margin-bottom: 50px;
}

.pdmc-box .title {
}

.pdmc-box .title h4 {
  font-size: 14px;
  margin: 0 0 15px;
  font-weight: 600;
  color: #142c4b;
  text-transform: uppercase;
}

.pdmc-box-m {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}

@media (min-width: 992px) {
  .pdmc-box-m {
    display: block !important;
    height: initial !important;
  }
}

.pdmc-m-inner {
  padding: 25px 40px;
}

.pdmc-d1-row {
  padding: 8px 0;
  --inner-space: 28px;
  --bt-space: var(--inner-space);
  --item: 3;
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--inner-space) * -0.5) calc(var(--bt-space) * -1);
}

.pdmc-d1-row .single {
  width: calc((100% / var(--item)) - var(--inner-space));
  margin: 0 calc(var(--inner-space) * 0.5) var(--bt-space);
}

.pdmc-d1-row .single h4 {
  font-size: 18px;
  margin: 0 0 3px;
  font-weight: 700;
  color: #4d505f;
}

.pdmc-d1-row .single p {
  font-size: 13px;
  font-weight: 400;
  color: #adadad;
  margin: 0 0 0;
}

.pdmc-d1-row .single h6 {
  font-size: 13px;
  font-weight: 400;
  color: #adadad;
  margin: 0 0 4px;
}

.fill-xt-outer {
  margin: 43px 0 0;
}

.fill-xt-outer h4 {
  font-size: 18px;
  margin: 0 0 12px;
  font-weight: 600;
  color: #4d505f;
}

.fill-xt-outer h4 .mbl-clr {
}

.fill-xt {
  height: 11px;
  border-radius: 4px;
  background: #eeeeee;
}

.fill-xt .filled {
  border-radius: inherit;
  height: 100%;
  background: #e83e8c;
}

.pdmc-sf-wrap {
  padding: 17px 0;
}

.pdmc-sf-single {
  padding: 0 0 30px;
  margin: 0 0 30px;
  border-bottom: 1px solid #e4e4e4;
}

.pdmc-sf-single:last-child {
  border-bottom: none;
  padding: 0 0 0px;
  margin: 0 0 0px;
}

.pdmc-sf-single h4 {
  font-size: 18px;
  margin: 0 0 17px;
  font-weight: 500;
  color: #e83e8c;
  display: flex;
  align-items: center;
}

.pdmc-sf-single h4 .icon {
  margin: 0 9px 0 0;
  width: 25px;
}

.pdmc-sf-single h4 .icon img {
  max-width: 100%;
}

.pdmc-sf-single .timestamp {
  font-size: 11px;
}
.pdmc-sf-single p {
  font-size: 16px;
  color: #acacac;
  margin: 0;
}

.pdmc-location-b-outer {
  display: flex;
}

.pdmc-location-b-outer .pdmc-m-inner {
  width: 50%;
}

.pdmc-location-b-outer .map {
  width: 50%;
}

.pdmc-location-b-outer .map iframe {
  width: 100%;
  height: 100%;
}

.pdmc-lct-wrap {
  padding: 18px 15px 18px 5px;
}

.pdmc-lct-single {
  margin: 0 0 35px;
}

.pdmc-lct-single:last-child {
  margin: 0 0 0px;
}

.pdmc-lct-single .top {
  margin: 0 0 7px;
  display: flex;
  align-items: center;
}

.pdmc-lct-single .top .icon {
  width: 22px;
  margin: 0 10px 0 0;
}

.pdmc-lct-single .top .icon img {
  max-width: 100%;
}

.pdmc-lct-single .top h5 {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

.pdmc-lct-single .top h4 {
  font-size: 18px;
  margin: 0 0 0 auto;
  font-weight: 700;
  color: #e83e8c;
}

.pdmc-lct-single p {
  font-size: 15px;
  font-weight: 400;
  color: #adadad;
  margin: 0;
}

.pdmc-mkt-wrap {
  padding: 20px 0 12px;
  --inner-space: 28px;
  --bt-space: var(--inner-space);
  --item: 3;
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--inner-space) * -0.5) calc(var(--bt-space) * -1);
  justify-content: center;
}

.pdmc-mkt-single {
  width: calc((100% / var(--item)) - var(--inner-space));
  margin: 0 calc(var(--inner-space) * 0.5) var(--bt-space);
  display: flex;
  align-items: center;
}

.pdmc-mkt-single .icon {
  width: 29px;
  margin: 0 8px 0 0;
}

.pdmc-mkt-single .icon img {
  max-width: 100%;
}

.pdmc-mkt-single .cont-s {
}

.pdmc-mkt-single .cont-s h4 {
  font-size: 17px;
  margin: 0 0 3px;
  color: #142c4b;
  font-weight: 700;
}

.pdmc-mkt-single .cont-s p {
  font-size: 14px;
  font-weight: 400;
  color: #adadad;
  margin: 0;
}

.property-details-side-column .top-button {
  margin: 0 0 53px;
}

.property-s-actions {
  padding: 5px 25px;
  border-radius: 4px;
  background: #142c4b;
  color: #fff;
}

.psa-single {
  padding: 18px 5px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(245, 245, 245, 0.25);
  cursor: pointer;
}

.psa-single:last-child {
  border-bottom: 0;
}

.psa-single .icon {
  width: 23px;
  margin: 0 12px 0 0;
}

.psa-single .icon img {
  max-width: 100%;
}

.psa-single .txt {
}

.property-details-img-slider {
  margin: 0 0 16px;
}

.property-details-img-slide {
}

.property-details-img-slide .image {
}

.property-details-img-slide .image-inner {
  padding-top: 62.2%;
  border-radius: 5px;
}

.slider-arrow1 .owl-nav button {
  font-size: 7px !important;
  color: #fff !important;
  background: rgba(20, 44, 75, 0.78) !important;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: 0.3s;
}

.slider-arrow1 .owl-nav button:hover {
  background: rgba(20, 44, 75, 1) !important;
}

.slider-arrow-center {
  --off: 10px;
}

.slider-arrow-center .owl-next {
  position: absolute;
  top: 50%;
  right: var(--off);
  transform: translate(0, -50%);
}

.slider-arrow-center .owl-prev {
  position: absolute;
  top: 50%;
  left: var(--off);
  transform: translate(0, -50%);
}

.owl-dots1 .owl-dots {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translate(-50%, 0);
}

.owl-dots1 .owl-dot {
  margin: 0 1px;
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background: rgba(20, 44, 74, 0.4) !important;
  transition: 0.3s;
}

.owl-dots1 .owl-dot.active {
  width: 28px !important;
  background: rgba(20, 44, 74, 1) !important;
}

* {
  scroll-margin: 150px;
}

/* ---property-details-section---end */
/* ---form-section */
.form-section {
}

.form-section-inner {
  display: flex;
  min-height: 100vh;
}

.desk-img-side {
  background: url("../img/form/form-side-bg.png") no-repeat center center/cover;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desk-img-side .logo-in {
}

.desk-img-side .logo-in img {
}

.form-fill-side {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.form-fill-side-inner {
  max-width: 540px;
  padding: 90px 50px;
}

.form-sss {
}

.form-sss .form-title {
  margin: 0 0 45px;
}

.form-sss .form-title h3 {
  font-size: 31.6px;
  margin: 0 0 20px;
  font-weight: 700;
  color: #142c4b;
}

.form-sss .form-title p {
  font-size: 15px;
  line-height: 1.65;
  font-weight: 400;
  color: #484b5b;
}

.form-sss .submit-button {
  margin: 70px 0 0;
}

.form-sss .bt-sde {
  margin: 50px 0 0;
  font-size: 16.87px;
  font-weight: 400;
  color: #142c4b;
  text-align: center;
}

.form-sss .bt-sde a {
  color: inherit;
  text-decoration: none;
}

.form-sss .bt-sde a strong {
  font-weight: 600;
}

.single-input-x {
  width: 100%;
}

.label-x {
  font-size: 15px;
  margin: 0 0 6px;
  width: 100%;
  color: #959595;
  cursor: pointer;
  font-weight: 400;
}

.input-x-outer {
  position: relative;
}

.input-x {
  width: 100%;
  height: 53px;
  width: 100%;
  padding: 15px 22px;
  background: #fdfdfd;
  border: 1px solid #f4f4f4;
  border-radius: 2px;
}
.input-xx {
  width: 100%;
  height: 53px;
  width: 100%;
  padding: 15px 22px;
  background: #fdfdfd;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
}

@media (max-width: 768px) {
  .input-xx {
    padding: 0px !important;
  }
}

.country-select-ax {
  position: absolute;
  top: 50%;
  left: 0;
  width: 93px;
  padding-left: 5px;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #f4f4f4;
}

.country-select-ax .flag {
  width: 31px;
}

.country-select-ax .fla img {
  width: 100%;
}

.country-select-ax select {
  font-size: 13px;
  font-weight: 500;
  color: #565656;
  background: transparent;
  border: none;
  padding: 0;
  margin: 4px 0 0;
  appearance: none;
}

.country-select-ax select option {
}

.country-select-ax ~ .input-x {
  padding-left: 110px;
}

.input-otp-wrap {
  --inner-space: 28px;
  --bt-space: var(--inner-space);
  --item: 4;
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--inner-space) * -0.5) calc(var(--bt-space) * -1);
  justify-content: center;
}

.input-x-ns {
  width: calc((100% / var(--item)) - var(--inner-space));
  margin: 0 calc(var(--inner-space) * 0.5) var(--bt-space);
  text-align: center;
  color: #e83e8c;
  font-size: 24px;
  font-weight: 700;
}

/* ---form-section-end */

/* responsive */
/* Normal desktop :1200px. */
@media (max-width: 1700px) {
  .hero-object-1 {
    left: 10%;
  }

  .hero-object-2 {
    right: 10%;
    bottom: 28%;
  }

  .about-object-1 {
    left: 12%;
  }

  .find-object-1 {
    top: -72px;
  }

  .find-object-2 {
    right: 10%;
  }
}

/* Normal desktop :992px. */
@media (min-width: 1200px) and (max-width: 1700px) {
  .container {
    max-width: 1095px;
  }
}

/* Normal desktop :992px. */
@media (max-width: 1199.99px) {
  h1 {
    font-size: 52px;
  }

  .hero-object-1 {
    left: 5%;
    width: 146px;
  }

  .hero-object-2 {
    right: 5%;
    width: 171px;
  }

  .works-item-img img {
    max-width: 280px;
    max-height: 340px;
  }

  .works-item {
    max-width: 700px;
  }

  .works-item:nth-child(1) .works-line-1 {
    max-width: 28%;
    height: 255px;
  }

  .works-item:nth-child(2) .works-line-2 {
    left: 20%;
    max-width: 34%;
    height: 229px;
  }

  .works-item:nth-child(3) .works-line-3 {
    max-width: 32%;
    height: 255px;
  }

  /*  */
  .all-properties-wrap {
    grid-column-gap: 40px;
    grid-row-gap: 35px;
  }

  .psa-single {
    padding: 14px 4px;
    font-size: 11px;
  }

  .psa-single .icon {
    width: 16px;
    margin: 0 10px 0 0;
  }

  .property-s-actions {
    padding: 4px 20px;
  }

  .property-details-side-column .top-button {
    margin: 0 0 42px;
  }

  .property-details-side-column {
    width: 200px;
    margin-left: 50px;
  }

  .pdmc-nav {
    margin: 0 0 30px;
  }

  .pdmc-m-inner {
    padding: 20px 30px;
  }

  .pdmc-nav ul li a {
    font-size: 13px;
  }

  .pdmc-box .title h4 {
    font-size: 13px;
    margin: 0 0 13px;
  }

  .pdmc-d1-row .single h4 {
    font-size: 16.5px;
    margin: 0 0 2px;
  }

  .pdmc-d1-row .single p {
    font-size: 11px;
  }

  .pdmc-d1-row {
    padding: 8px 0;
    --inner-space: 25px;
  }

  .fill-xt-outer h4 {
    font-size: 15px;
    margin: 0 0 11px;
  }

  .fill-xt-outer {
    margin: 30px 0 0;
  }

  .pdmc-sf-single h4 {
    font-size: 12px;
    margin: 0 0 9px;
  }

  .pdmc-sf-single p {
    font-size: 11px;
    line-height: 1.7;
  }

  .pdmc-sf-single h4 .icon {
    margin: 0 7px 0 0;
    width: 20px;
  }

  .pdmc-sf-single {
    padding: 0 0 20px;
    margin: 0 0 21px;
  }

  .pdmc-box {
    margin-bottom: 35px;
  }

  .pdmc-lct-single .top h4 {
    font-size: 15.5px;
  }

  .pdmc-lct-single .top h5 {
    font-size: 13px;
  }

  .pdmc-lct-single p {
    font-size: 13px;
  }

  .pdmc-lct-single .top .icon {
    width: 18px;
    margin: 0 8px 0 0;
  }

  .pdmc-lct-single .top {
    margin: 0 0 2px;
  }

  .pdmc-lct-single {
    margin: 0 0 25px;
  }

  .pdmc-lct-wrap {
    padding: 13px 12px 13px 3px;
  }

  .pdmc-mkt-wrap {
    padding: 20px 0 12px;
    --inner-space: 17px;
    margin: 0 -18px;
  }

  .form-fill-side-inner {
    max-width: 448px;
    padding: 60px 50px;
  }
}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1199.99px) {
  .container {
    max-width: 920px;
  }
}

/* Tablet desktop :768px. */
@media (max-width: 991.98px) {
  .hero-padding {
    padding-top: 56px;
    padding-bottom: 65px;
  }

  .hero-object-1 {
    display: none;
  }

  .mission-card {
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 23px;
    background-color: #fff;
  }

  .mission-card.active {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }

  .mission-area .section-title {
    margin-bottom: 34px;
  }

  .works-line-1,
  .works-line-2,
  .works-line-3,
  .works-line-4 {
    display: none;
  }

  .works-item-img {
    order: 1;
    width: 100%;
    margin-bottom: 30px;
    height: 250px;
  }

  .works-item-content {
    order: 2;
    width: 100%;
    padding-left: 77px;
  }

  .works-count {
    position: absolute;
    left: 0px !important;
    top: 0px !important;
  }

  .works-item {
    max-width: 600px;
    flex-wrap: wrap;
    margin: 0 auto;
    position: relative;
  }

  .works-item-img img {
    max-width: 280px;
    max-height: 250px;
  }

  .works-item::after {
    position: absolute;
    left: 25px;
    top: 280px;
    width: 2px;
    background-image: url("../img/long-line.png");
    content: "";
    height: 100%;
  }

  .works-item:last-child::after {
    display: none;
  }

  .find-object-1 {
    top: -72px;
    display: none;
  }

  .find-object-2 {
    right: 10%;
    top: 0;
    max-width: 140px;
  }

  .works-padding {
    padding-top: 50px;
    padding-bottom: 65px;
  }

  .find-padding {
    padding-top: 50px;
    padding-bottom: 65px;
  }

  .footer-top {
    padding-top: 50px;
    padding-bottom: 28px;
  }

  .footer-location {
    margin-bottom: 50px;
  }

  .find-custom-width {
    overflow-x: scroll;
    scrollbar-width: none;
  }

  .find-custom-width > .row {
    width: 952px;
  }

  .main-wrapper {
    overflow-x: hidden;
  }

  .main-wrapper {
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }

  .main-wrapper.active {
    background-color: #142c4b;
  }

  .main-wrapper.active .inner-wrapper {
    margin-left: -212px;
    width: 100%;
    height: 100%;
    margin-top: 60px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.06);
    border-radius: 33.5453px;
    overflow-y: scroll;
    transition: 0.3s;
    background-color: #fff;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }

  .main-wrapper.active header.header {
    border-top-right-radius: 33.5453px;
  }

  .main-wrapper.active header.header.sticky {
    border-radius: 0px;
  }

  .main-wrapper.active header.header.sticky.bg-white {
    left: -212px;
  }

  .m-shapes.active::after,
  .m-shapes.active::before {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 60px;
    background-color: #142c4b;
    content: "";
    z-index: 12;
  }

  .m-shapes.active::after {
    top: auto;
    bottom: 0px;
  }

  .header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 12;
  }

  .main-wrapper.active .header {
    left: -212px;
    top: 60px;
  }

  .hero-padding {
    padding-top: 96px;
    padding-bottom: 98px;
  }

  /*  */
  .all-properties-wrap {
    grid-template-columns: repeat(1, 1fr);
  }

  .prop-shape {
    display: none;
  }

  .sgl-properties.mbl-first {
    grid-row-start: 1;
  }

  .properties-top {
    flex-wrap: wrap;
  }

  .properties-top .right {
    width: 100%;
    order: -1;
    margin: 0 0 26px;
    flex-wrap: wrap;
  }

  .properties-top .left {
    width: 100%;
  }

  .properties-top {
    margin: 0 0 30px;
  }

  .properties-top .right .ind-select {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .property-details-title .left {
    width: 65%;
  }

  .property-details-title .left h2 {
    margin: 0 0 0px;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    transition: 0.3s ease;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .property-details-row {
    flex-wrap: wrap;
  }

  .property-details-side-column {
    order: -1;
    width: 100%;
    margin-left: 0px;
  }

  .property-details-row {
    margin: 20px 0 0;
  }

  .property-details-side-column .top-button {
    margin: 0 0 15px;
  }

  .property-s-actions {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }

  .psa-single {
    border-bottom: 1px solid rgba(245, 245, 245, 0.25);
    border-right: 1px solid rgba(245, 245, 245, 0.25);
    width: 50%;
    padding: 18px 13px;
  }

  .property-details-main-column {
    padding: 25px 0 0;
  }

  .pdmc-box {
    border: 1px solid #e4e4e4;
    border-radius: 4px;
  }

  .pdmc-box-m {
    border-radius: 0;
    border: none;
  }

  .pdmc-box .title {
    padding: 23px 15px;
    display: flex;
    justify-content: space-between;
  }

  .pdmc-box .title h4 {
    font-size: 13px;
    margin: 0;
  }

  .pdmc-box .title .ind-icon {
    font-size: 14px;
    line-height: 1;
  }

  .pdmc-box .title.collapsed .ind-icon {
    transform: rotate(180deg);
    /* display: none !important; */
  }
  @media (min-width: 991px) {
    .general-detail-icon {
      display: none !important;
    }
  }

  .pdmc-m-inner {
    padding: 0px 17px 20px;
  }

  .fill-xt-outer h4 .mbl-clr {
    color: #e83e8c;
  }

  .fill-xt {
    height: 23px;
  }

  .pdmc-d1-row .single {
    text-align: center;
  }

  .pdmc-sf-wrap {
    padding: 3px 0 7px;
  }

  .pdmc-location-b-outer {
    flex-wrap: wrap;
  }

  .pdmc-location-b-outer .pdmc-m-inner {
    width: 100%;
  }

  .pdmc-location-b-outer .map {
    width: 100%;
    padding: 0 17px 17px;
  }

  .pdmc-lct-wrap {
    padding: 4px 0px 13px 0px;
  }

  .pdmc-location-b-outer .map {
    height: 300px;
  }

  .pdmc-mkt-wrap {
    padding: 15px 0 0px;
    --inner-space: 30px;
    margin: 0 0px;
    width: calc(100% + 36px);
    margin-left: -18px;
  }

  .pdmc-box {
    margin-bottom: 20px;
  }

  .desk-img-side {
    display: none;
  }

  .form-section-inner {
    min-height: 0;
  }

  .form-section-inner {
    /* padding: 0 12px; */
    /* max-width: 720px; */
    margin: 0 auto;
  }

  .form-mbl-bg .inner-wrapper {
    background: url("../img/form/form-side-bg.png") no-repeat center
      center/cover;
    color: #fff;
  }

  .form-fill-side {
    margin: 0 auto;
    /* max-width: 448px; */
    width: 100%;
  }

  .form-fill-side-inner {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
  }

  .form-sss {
  }

  .form-sss .form-inner {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .form-in-cont-p {
    margin: 0 auto;
    max-width: 448px;
    width: 100%;
  }

  .form-sss .form-main {
    padding: 60px 15px 0;
    border-radius: 8px 8px 0 0;
    background: rgba(255, 255, 255, 0.219);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .form-sss .form-main .form-in-cont-p {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .form-sss .form-bottom {
    margin: auto 0;
    padding: 30px 0;
  }

  .form-sss .submit-button {
    margin-top: auto;
  }

  .form-sss .bt-sde {
    margin-bottom: auto;
  }

  .form-sss .form-title {
    margin: 40px 15px 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 25vh;
  }

  .form-mbl-bg .form-sss .form-title {
    text-align: center;
  }

  .form-mbl-bg .form-sss .form-title * {
    color: #fff !important;
  }

  .form-mbl-bg .label-x {
    color: #fff;
  }

  .form-mbl-bg .bt-sde {
    color: #fff;
  }
}

/* small mobile :320px. */
@media (max-width: 767px) {
  h1 {
    font-size: 32px;
  }

  p {
    font-size: 13px;
    line-height: 1.5;
  }

  h6 {
    font-size: 14px;
  }

  .hero-content h1 {
    line-height: 45px;
  }

  .hero-content p {
    color: #a9a9a9;
    font-size: 13px;
    line-height: 22px;
  }

  .hero-object-2 {
    right: 1%;
    width: 84px;
    top: 31%;
  }

  .hero-content a.op-btn.op-btn.op-btn-blue {
    padding: 13px 39px;
    font-size: 14px;
  }

  .hero-padding {
    padding-top: 96px;
    padding-bottom: 98px;
  }

  .mission-padding {
    padding-top: 33px;
    padding-bottom: 15px;
  }

  .mission-card {
    box-shadow: 0px 3.66716px 36.6716px rgba(0, 0, 0, 0.06);
    border-radius: 3.66716px;
    border: none;
  }

  .missing-card-content p.large-text {
    font-size: 13px !important;
  }

  .about-object-1 {
    display: none;
  }

  .about-padding {
    padding-top: 35px;
    padding-bottom: 40px;
  }

  .about-area .section-title {
    margin-bottom: 38px;
  }

  .works-padding {
    padding-top: 30px;
    padding-bottom: 40px;
  }

  .works-count {
    line-height: 31px;
    height: 30px;
    width: 30px;
    font-size: 13px;
  }

  .works-item::after {
    left: 16px;
    top: 280px;
  }

  .works-item-content {
    padding-left: 40px;
  }

  .works-item-img img {
    max-width: 207px;
    max-height: 240px;
  }

  .works-item-img {
    height: 240px;
    display: flex;
    align-items: center;
    padding-left: 30px;
  }

  .works-item:nth-child(2) .works-item-img,
  .works-item:nth-child(4) .works-item-img {
    justify-content: flex-end;
  }

  .find-object-2 {
    right: 3%;
    top: 0;
    max-width: 101px;
  }

  .find-padding {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .find-area .section-title {
    margin-bottom: 34px;
  }

  .find-custom-width > .row {
    width: 697px;
  }

  .find-info-head h4 {
    font-size: 25px;
  }

  .find-info-item span {
    font-size: 11px !important;
  }

  .find-info {
    height: 460px;
    padding: 27px 15px 24px;
  }

  .large-text {
    font-size: 15px !important;
  }

  .find-img img {
    height: 460px;
  }

  .button-link li a.large-text {
    padding: 12px 6px;
  }

  .button-link li a {
    font: 16px;
    color: #142c4b;
    padding: 16px 15px;
  }

  .join-padding {
    padding-top: 34px;
    padding-bottom: 40px;
  }

  .join-area .section-title {
    margin-bottom: 45px;
  }

  .link-with-icon li a {
    font-size: 15px;
  }

  .social-box.socila-round a {
    height: 48px;
    width: 48px;
  }

  .help-center {
    margin-bottom: 27px;
  }

  .faq-padding {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .faq.accordion .accordion-button {
    font-size: 14px;
    padding-left: 47px;
  }

  .faq.accordion .accordion-button span.acc-count {
    position: absolute;
    left: 0px;
  }

  .faq.accordion .accordion-body {
    padding-left: 47px;
  }

  .faq.accordion .accordion-button {
    padding: 15.5px 12px;
    padding-left: 47px;
  }

  .faq-area .section-title {
    margin-bottom: 41px;
  }

  .footer-social a {
    height: 50px;
    width: 50px;
  }

  .footer-contact-item {
    position: relative;
    padding-left: 24px;
  }

  .footer-shedule {
    margin-top: 23px;
    padding-left: 22px;
  }

  .contact-form {
    padding: 34px 30px;
  }

  /*  */
  .properties-section {
    padding: 27px 0 50px;
  }

  .pagination ul li a {
    font-size: 13px;
  }

  .pagination ul li a.active {
    font-size: 14px;
  }

  .pagination-section {
    /* padding: 20px 0 18px; */
  }

  .property-details-title .left h2 {
    font-size: 16px;
    margin: 0 0 3px;
  }

  .property-details-title .right h3 {
    font-size: 18px;
    margin: 0 0 3px;
  }

  .location-x {
    font-size: 11px;
  }

  .pdmc-d1-row {
    --item: 2;
  }

  .pdmc-mkt-wrap {
    --item: 1;
  }

  .property-details-section {
    padding: 27px 0 55px;
  }

  .property-details-side-column {
    padding-top: 0px;
  }

  .form-in-cont-p {
    max-width: 370px;
  }

  .input-otp-wrap {
    --inner-space: 20px;
  }
}

/* Large Mobile :480px. */
@media only screen and (min-width: 375px) and (max-width: 767px) {
  .container {
    padding: 0px 24px;
  }
}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .container {
    /* width: 450px */
  }

  .footer-copy.ps-lg-4 {
    flex: 0 0 auto;
    width: 41%;
  }
}

@media (max-width: 600px) {
  .footer-text,
  .footer-widget,
  .footer-location {
    margin: auto;
    text-align: center;
    margin-top: 30px !important;
  }

  .footer-bottom .footer-copy.ps-lg-4 {
    padding: 10px 0;
    margin: auto;
    width: auto;
    text-align: center;
  }

  .footer-social {
    text-align: center;
  }

  .footer-social a,
  .footer-social a:first-child {
    border: none;
  }

  .property-details-img-gallery {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }
}
